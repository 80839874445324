.loginheader {
  width: 100%;
  min-height: 200px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}
.loginHolder {
  min-height: 800px;
  border-width: 0.8px;
  border-color: #c7c7c7;
  margin-top: 200px !important;
  padding: 20px;
}
.loginButton {
  margin-top: 20px;
}

.loginAsOrganizer {
  margin-left: 10px;
  width: auto;
  padding: 10px;
  background-color: orange;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
}

.organizerTop {
  width: 100%;
  height: 40px;
  background-color: #ff8001;
  display: flex;
}

.organizerLogginName {
  margin-top: 5px;
  margin-bottom: 1rem;
  text-align: left;
  margin-left: 10px;
}

.organizerGoBack {
  margin-top: 5px;
  margin-bottom: 1rem;
  /* text-align: right; */
  margin-left: 60%;
  color: #ffffff;
}

.loginButton {
  width: 100% !important;
  background-color: #077d7b;
  color: #ffffff;
  border: none;
  margin-top: 40px;
}

.infoContainer {
  min-height: 500px;
}
.tr {
  min-height: 90px;

  border: 0.5px solid grey;
  padding: 10px;
}
.td {
  min-height: 50px !important;
}
